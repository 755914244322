@import '../variables';

.yieldContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $light-gray;
  padding: 5rem;
  grid-area: productionMetric3;
  margin: 1em;

  .value {
    font-size: 3rem;
    padding-top: 1.5rem;
    color: $primary-blue;
  }
  .valueLabel {
    padding-top: 1rem;
    color: $light-gray;
    text-align: center;
  }
}
