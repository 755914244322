@import '../variables';

.filterContainer {
  margin: 0 1rem;
  grid-area: filters;
  background-color: #f2f5f8;
  padding: 1rem;
  display: grid;
  grid-template-rows:
    [dateLabel-start plantLabel-start mixerLabel-start mixerIdLabel-start] 1rem
    [dateLabel-end plantLabel-end mixerLabel-end mixerIdLabel-end dateFilter-start plantFilter-start mixerFilter-start mixerIdFilter-start];
  grid-template-columns:
    [dateLabel-start dateFilter-start] 16%
    [dateLabel-end dateFilter-end plantLabel-start plantFilter-start] 28.3%
    [plantLabel-end plantFilter-end mixerLabel-start mixerFilter-start] 28.3%
    [mixerLabel-end mixerFilter-end mixerIdLabel-start mixerIdFilter-start];
  align-items: center;
  height: 4rem;
  .filterLabel {
    font-size: 0.75rem;
    color: $light-gray;
    &:nth-child(1) {
      grid-area: dateLabel;
    }
    &:nth-child(2) {
      grid-area: plantLabel;
    }
    &:nth-child(3) {
      grid-area: mixerLabel;
    }
    &:nth-child(4) {
      grid-area: mixerIdLabel;
    }
  }
  .filterSection {
    &.dateFilter {
      grid-area: dateFilter;
    }
    &:nth-child(2) {
      grid-area: plantFilter;
    }
    &:nth-child(3) {
      grid-area: mixerFilter;
    }
    &:nth-child(4) {
      grid-area: mixerIdFilter;
    }
    input {
      border: none;
      border-bottom: 1px solid $light-gray;
      font-size: 1rem;
      background-color: #f2f2f2;
      width: 6rem;
    }
  }
}

.filterContainer.adminFilters {
  height: 10rem;
  grid-template-rows:
    [dateLabel-start companyLabel-start plantLabel-start] 1rem
    [dateLabel-end companyLabel-end plantLabel-end dateFilter-start companyFilter-start plantFilter-start] 5rem
    [dateFilter-end companyFilter-end plantFilter-end mixerLabel-start mixerIdLabel-start] 1rem
    [mixerLabel-end mixerIdLabel-end mixerFilter-start mixerIdFilter-start] 5rem;
  grid-template-columns:
    [dateLabel-start dateFilter-start mixerLabel-start mixerFilter-start] 20%
    [dateLabel-end dateFilter-end companyLabel-start companyFilter-start] 40%
    [mixerLabel-end mixerFilter-end companyLabel-end companyFilter-end mixerIdLabel-start mixerIdFilter-start plantLabel-start plantFilter-start];
  align-items: center;
  .filterLabel {
    font-size: 0.75rem;
    color: $light-gray;
    &:nth-child(1) {
      grid-area: dateLabel;
    }
    &:nth-child(2) {
      grid-area: companyLabel;
    }
    &:nth-child(3) {
      grid-area: plantLabel;
    }
    &:nth-child(4) {
      grid-area: mixerLabel;
    }
    &:nth-child(5) {
      grid-area: mixerIdLabel;
    }
  }
  .filterSection {
    &.dateFilter {
      grid-area: dateFilter;
    }
    &.companyFilter {
      grid-area: companyFilter;
    }
    &.plantFilter {
      grid-area: plantFilter;
    }
    &.mixerFilter {
      grid-area: mixerFilter;
    }
    &.mixTypeFilter {
      grid-area: mixerIdFilter;
    }
  }
}

@media (max-width: 1500px) {
  .filterContainer {
    height: 10rem;
    grid-template-rows:
      [dateLabel-start plantLabel-start ] 1rem
      [dateLabel-end plantLabel-end dateFilter-start plantFilter-start] 5rem
      [dateFilter-end plantFilter-end mixerLabel-start mixerIdLabel-start] 1rem
      [mixerLabel-end mixerIdLabel-end mixerFilter-start mixerIdFilter-start] 5rem;
    grid-template-columns:
      [dateLabel-start dateFilter-start mixerLabel-start mixerFilter-start] 50%
      [ dateLabel-end dateFilter-end mixerLabel-end mixerFilter-end plantLabel-start plantFilter-start mixerIdLabel-start mixerIdFilter-start];
  }
}
