@import '../variables';

.dailyViewContainer {
  .sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      font-size: 2rem;
      margin-left: 1rem;
      flex-grow: 1;
    }
    button {
      margin-right: 1rem;
      height: 2rem;
    }
  }
  .breadcrumbs {
    margin: 1rem 0 0 1rem;
    a {
      text-decoration: none;
      color: $primary-blue;
    }
  }
  .dailyGraphContainer {
    padding: 0 2rem;
    border: 1px solid $light-gray;
    border-top: 5px solid $primary-blue;
    margin: 1rem 0 0;
    background-color: $white;
    .dailySectionTitle {
      padding: 1rem 1rem;
      font-size: 2rem;
      border-bottom: 1px solid $light-gray;
    }
  }
  .dailyToolTip {
    .dateRow {
      background: $light-gray;
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
    }
    .info {
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;
    }
  }
  .graphContainer {
    position: relative;
    margin: 0 2rem;
  }
}
