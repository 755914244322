@import '../variables';

.productionTimeContainer {
  border: 1px solid $light-gray;
  padding: 2rem;
  grid-area: productionMetric1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em;

  .mixTimeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .value {
      font-size: 3rem;
      padding-top: 1.5rem;
      color: $primary-blue;
    }
    .valueLabel {
      padding-top: 1rem;
      color: $light-gray;
      text-align: center;
    }
  }
}
