@import '../variables';

.infoContainer {
  .submitRow {
    display: flex;
    flex-direction: row;
    height: 2rem;
    margin: 4rem 3rem 2rem;
  }
  fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    margin: 0;
    padding: 0;
  }

  input {
    height: 2rem;
  }

  .formError {
    color: red;
    font-size: 10px;
  }
}
.pageHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  form {
    margin-bottom: 0;
  }
  .formError {
    color: red;
    font-size: 10px;
  }
  h3 {
    padding: 0 3rem;
  }
}

table.plantTable {
  padding: 0 2.5rem;
  th {
    padding: 0.5rem;
    border-bottom: 1px solid $light-gray;
  }
  td {
    padding: 0.5rem;
    border-bottom: 1px solid $light-gray;
  }
}

.pageSelector {
  padding-inline-start: 3rem;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  li {
    display: flex;
    border: 1px solid $light-gray;
    cursor: pointer;
    a {
      padding: 10px 15px;
    }
    &.selected {
      background: $light-blue;
      color: $light-gray;
    }
    &:hover {
      background: $light-blue;
    }
    &.disabled {
      cursor: not-allowed;
      background: $light-gray;
    }
  }
}

.buttonStyling {
  margin: 0 3rem;
}
