@import '../variables';

.modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  .modalTitle {
    position: absolute;
    top: 26%;
    left: 41%;
    font-size: 1.5rem;
    z-index: 3;
  }
  .modalBody {
    position: absolute;
    top: 25%;
    left: 40%;
    z-index: 2;
    border: 1px solid;
    border-radius: 10px;
    padding: 2rem;
    background-color: #ffffff;
    .buttonContainer {
      text-align: center;
      display: flex;

      button {
        margin: 0 3rem;
        &.formButton {
          margin: 0.5rem 2rem;
        }
      }
    }
    .formBody {
      display: flex;
      flex-direction: column;
      margin: 3rem 2rem;
      width: 15rem;
      .inputField {
        border: none;
        border-bottom: 1px solid $light-gray;
        font-size: 1rem;
      }
      .formError {
        margin-top: 0.25rem;
        color: red;
        font-size: 10px;
      }
    }
  }
}
