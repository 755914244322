@import '../variables';

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  .loadingMessage {
    font-size: 4rem;
  }
  .loadingBar {
    height: 2rem;
    width: 15vw;
    display: flex;
    flex-direction: row;
    .loadingIndicator {
      height: 3rem;
      width: 3rem;
      background: $light-blue;
      border-radius: 3rem;
      margin: 0 0.5rem;
      opacity: 0;
      &:nth-child(1) {
        animation: loading 1.5s ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: loading 1.5s ease-in-out infinite;
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation: loading 1.5s ease-in-out infinite;
        animation-delay: 0.4s;
      }
      &:nth-child(4) {
        animation: loading 1.5s ease-in-out infinite;
        animation-delay: 0.6s;
      }
    }
  }
}

.loadingContainer::after {
  position: absolute;
  content: '';
  background-color: $light-gray;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: -1;
}

@keyframes loading {
  0%,
  20% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
}
