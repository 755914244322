@import '../variables';

.dayViewContainer {
  .sectionHeader {
    display: grid;
    align-items: baseline;
    grid-template-areas:
      'title button'
      'subtitle subtitle'
      'date .';
  }
  h2 {
    font-size: 2rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    grid-area: title;
  }
  button {
    margin-right: 1rem;
    height: 2rem;
    justify-self: end;
    grid-area: button;
  }
  h3 {
    margin-left: 1rem;
    margin: 0 1rem 0.5rem;
    grid-area: subtitle;
  }
  h5 {
    margin-left: 1rem;
    margin-top: 0;
    grid-area: date;
  }
  .breadcrumbs {
    margin: 1rem 0 0 1rem;
    a {
      text-decoration: none;
      color: $primary-blue;
    }
  }
  .dayGraphContainer {
    padding: 0 2rem;
    border: 1px solid $light-gray;
    border-top: 5px solid $primary-blue;
    margin: 1rem 0 0;
    background-color: $white;
    .daySectionTitle {
      padding: 1rem 1rem;
      font-size: 2rem;
      border-bottom: 1px solid $light-gray;
    }
    .batchGraphContainer {
      padding: 1.5rem 0 0;
      border-bottom: 1px solid $light-gray;
      &:first-of-type {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }

      .dayBatchXAxis {
        font-weight: bolder;
      }
    }
  }
  .dayToolTip {
    .dateRow {
      background: $light-gray;
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
    }
    .info {
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;
    }
  }
  .graphsContainer {
    position: relative;
    margin: 0 2rem;
  }
  .emptyGraphs {
    content: '';
    height: 80%;
    width: 100%;
  }
  .hasData {
    display: none;
  }
  .noData {
    text-align: center;
    padding: 2rem;
    font-size: 1.5rem;
  }
}
