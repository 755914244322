@import 'variables';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.appContainer {
  height: 100%;
  display: grid;
  grid-template-columns: [header-start nav-start] 5.5em [nav-end main-start];
  grid-template-rows: [header-start] 3em [header-end nav-start main-start];
  background-color: $background-gray;

  header {
    display: flex;
    grid-area: header;
    padding: 0.5em;
    background: $dark-blue;
    color: #ffffff;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      font-size: 1.3em;
      img {
        max-height: 1.5em;
        margin-right: 0.5em;
      }
    }
    .logout {
      margin: 0 0.25rem 0 0.75rem;
    }
  }

  nav {
    grid-area: nav;
    background: #000000;
    text-align: center;
    a {
      text-decoration: none;
      color: #ffffff;
      & > .navLinkWrapper {
        padding: 10px 0;
      }
      &.active > .navLinkWrapper {
        background: $light-blue;
      }
    }
  }
  main {
    grid-area: main;
  }
}

:global() {
  button {
    padding: 0.4rem 0.75rem;
    margin: 0 0.25rem;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: $primary-blue;
    color: $primary-blue;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background-color: $background-gray;
    }
    &:active {
      background-color: $primary-blue;
      color: $light-gray;
    }
    &:disabled {
      background-color: $light-gray;
      color: #000000;

      border-color: $light-gray;
      cursor: not-allowed;
    }
  }
}

:global(.isIE) {
  text-align: center;
  font-size: 2rem;
  padding-top: 15rem;
}
