@import '../variables';

.dashboardContainer {
  h2 {
    font-size: 2rem;
    margin: 0.5rem 0 0 2rem;
  }
  .metricsContainer {
    position: relative;
    display: grid;
    grid-template-columns: [title-start qualityTitle-start mixTitle-start qualityMetrics-start productionMixMetrics-start] 73% [title-end detailsButton-start];
    grid-template-rows: [title-start detailsButton-start] 3em [title-end detailsButton-end qualityTitle-start] 1em [qualityTitle-end qualityMetrics-start] 21.5em [qualityMetrics-end mixTitle-start] 1em [mixTitle-end productionMixMetrics-start];
    border-top: 5px solid $primary-blue;
    background: #ffffff;
    margin: 0 2rem;
    .dashboardTitle {
      grid-area: title;
      border-bottom: 1px solid $light-gray;
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
    }
    .dashboardQualityTitle {
      grid-area: qualityTitle;
      padding: 0.5rem 1rem;
    }
    .dashboardMixTitle {
      grid-area: mixTitle;
      padding: 0.5rem 1rem;
    }
    .dailyView {
      grid-area: detailsButton;
      text-align: right;
      border-bottom: 1px solid $light-gray;
      button {
        margin: 0.5rem 1rem;
      }
    }
    .dashboardQualityCharts {
      grid-area: qualityMetrics;
      display: grid;
      grid-template-columns: [qualityMetric1-start] 46% [qualityMetric1-end qualityMetric2-start] 27% [qualityMetric2-end qualityMetric3-start];
    }
    .dashboardMixCharts {
      grid-area: productionMixMetrics;
      display: grid;
      grid-template-columns: [productionMetric1-start] 46% [productionMetric1-end productionMetric2-start] 27% [productionMetric2-end productionMetric3-start];
    }
  }
}

@media (max-width: 1280px) {
  .dashboardContainer {
    .metricsContainer {
      display: flex;
      flex-direction: column;
      .dashboardQualityCharts,
      .dashboardMixCharts {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
