body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.container {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  flex-direction: column;

  .welcome {
    padding: 0.5em;
    font-size: 1.3em;
    color: #000;
  }

  .loginForm {
    margin-top: 1em;
    button {
      padding: 0.4rem 0.75rem;
      margin: 0 0.25rem;
      border-radius: 10px;
      border-width: 1px;
      border-style: solid;
      background-color: #007aff;
      color: #fff;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
        background-color: #42a5e2;
      }
      &:active {
        background-color: #016fb5;
      }
    }
  }
}
