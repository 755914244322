@import '../variables';

.dropdown {
  margin: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    font-style: normal;
    font-size: 1rem;
    color: $light-gray;
    padding: 0.3rem 0;
  }
  .formControlFilter {
    position: absolute;
    bottom: -0.75rem;
    left: -3rem;
    min-width: 50%;
  }
  .formControlFilterDropdown {
    position: absolute;
    bottom: -0.75rem;
    left: -3rem;
    min-width: 60%;
    max-width: 120%;
  }
  .formControl {
    width: 25%;
  }
  .chipInput {
    div {
      height: inherit;
      display: flex;
      flex-wrap: wrap;
      max-height: 3.4rem;

      // For Edge browser. Prevents nested overflow that caused browser to freeze.
      &:first-child {
        overflow: auto;
      }
    }
    .placeHolder {
      font-style: normal;
      font-size: 1rem;
    }
    .chips {
      margin: 0.2rem 0.2rem 0;
    }
  }
}

@media (max-width: 1180px) {
  .dropdown {
    .formControlFilterDropdown {
      min-width: 85%;
    }
  }
}
